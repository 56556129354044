import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import AboutBrands from "../components/AboutBrands/AboutBrands";
import Description from "../components/Description/Description";

import aboutVideoHevc from "../videos/about/about-h265.mp4";
import aboutVideoWebm from "../videos/about/about-vp9.webm";
import aboutVideoMp4 from "../videos/about/about-h264.mp4";
import aboutPoster from "../videos/about/about-poster.jpg";

const About = () => (
  <Layout pageUrl="about">
    <SEO title="About" />
    <Hero
      home={false}
      pageTitle="About"
      videoSrcHevc={aboutVideoHevc}
      videoSrcWebm={aboutVideoWebm}
      videoSrcMp4={aboutVideoMp4}
      videoPoster={aboutPoster}
    />
    <Description
      heading="A name, a studio, a team of experts"
      description="Parallel Milano is a photographic studio based in Milan and Lugano specialized in services for the fashion world. Our team is made up of photographers, video-makers, experts ready to support brands and customers in the new challenges imposed by the digital development. We create shoots for e-commerce, lookbooks, videos and special projects for social media, guaranteeing flexibility and maximum attention to detail, but also managing large production flows in a short time."
    />
    <AboutBrands brandTitle="We work for" />
  </Layout>
);

export default About;
